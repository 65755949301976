
import logoImg from '../assets/img/logo.png'


export function Page404(){
    const currentUrl = window.location.href
    
    return (
        <div id="page-room">
            <header>
                <div className="content">
                    <img src={logoImg} alt="Logo"/>
                </div>
            </header>
            <main className='content'>
                <div className='room-title'>
                    <h1>Página não encontrada</h1>
                    <h3>{currentUrl}</h3>
                </div>
                <div className='question-list'>
                </div>
            </main>
        </div>
    )

}